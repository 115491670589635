import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from 'reactstrap';

class NotFoundPage extends React.Component {
  render() {

    let images = [
      "/assets/404/max.gif",
      "/assets/404/black_books.gif",
      "/assets/404/wile_1.gif",
      "/assets/404/wile_2.gif"
    ];
    let rnd = Math.floor(Math.random() * images.length);
    
    let image = images[rnd];

    return (
     
    <Layout>
    <SEO title="404: Not Found" description="The page you are looking for doesn't exist." />
    <div>
      <Container fluid className="w-80 content-page block">
            <Row>
          <Col>
          <div>
          <h1>Zounds! I can’t find what you are looking for!</h1>

          <div className="text-center" style={{marginBottom: "3rem"}}>
          <img className="img-fluid" src={image} width="70%"/>
          </div>

          <p>I’m awfully sorry, but either I misplaced it, or it wasn’t there in  the first place. If you came from somewhere, you can try to go back and  try again..</p>
        
          <p>Alternatively, you can poke around the site a bit, maybe you find  what you’re looking for, or maybe you find something totally different.  Might be your lucky day.
          </p>
          <p>If you think you shouldn’t be getting this message, please send me a short note to <a href="mailto:hello@fwends.net">hello@fwends.net</a> and I’ll look into it.</p>

        <p>Again, very sorry.</p>
          </div>
            

          </Col>
        </Row>
      </Container>
  </div>
    </Layout>
    )
  }
}
export default NotFoundPage
